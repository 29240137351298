<template>
  <v-container :style="{'background-color': ($vuetify.theme.dark ? '#000000de' : '#ffffffde')}">
    <!-- start spiner --->
    <v-row align="center" class="ma-3 pt-10" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <template v-else-if="isLoggedIn">
      <v-card-title class="text-center"
        :style="{'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}"
        >{{ $t('purchaseAtendees') }}:</v-card-title
      >
      <v-row justify="center" v-if="purchase && purchase.attendees && purchase.attendees.length">
        <v-col
          v-for="(item, index) in purchase.attendees"
          :key="index"
          cols="12"
          sm="6"
        >
          <PurchaseDetailAttendee :attendee="item" :event="purchase.event" />
        </v-col>
      </v-row>
      <v-card-title
        v-else
        class="text-center"
        :style="{'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}"
      >
        {{ $t('thereAreNoAttendeesForThisPurchase')}}
      </v-card-title>
    </template>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import notifications from '@/plugins/notifications'
import eventsService from '@/services/eventsService'
import PurchaseDetailAttendee from './PurchaseDetailAttendee'
export default {
  components: { PurchaseDetailAttendee },
  created () {
    this.isLoading = true

    if (this.isLoggedIn) {
      this.getPurchase()
    } else {
      this.$store.dispatch('authKeybe/isLoggedIn')
        .then(isLoggedIn => {
          if (isLoggedIn) {
            notifications.success(this.$t('pleaseLoginBeforeViewingYourPurchases'))
            this.openKeybeUi()
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  data: () => ({
    purchase: {},
    isLoading: false
  }),
  computed: {
    ...mapState({
      isLoggedIn: state => state.authKeybe.isLoggedIn
    })
  },
  methods: {
    async getPurchase () {
      this.isLoading = true
      // console.log('this.$route.params')
      // console.log(this.$route.params)

      const { id } = this.$route.params
      try {
        const response = await eventsService
          .getPurchase(id)
        this.purchase = response
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    openKeybeUi () {
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
    }
  },
  watch: {
    isLoggedIn (val) {
      this.getPurchase()
    }
  }
}
</script>
