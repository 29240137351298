<template>
  <v-card max-width="375">
    <v-img
      style="cursor: pointer"
      @click="goToOnlineEvent"
      :src="event.coverImage" height="200px"
    />
    <v-card-text
      @click="goToOnlineEvent"
      style="cursor: pointer;"
      :style="{'background-color': ($vuetify.theme.dark ? '#000000de' : '#ffffffde'),
               'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}"
    >
      <v-row>
        <v-card-title
          class="font-weight-medium text-h5 py-0"
          style="word-break: break-word"
        >
          {{ event.title }}
        </v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle class="py-0" :style="{'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}">
          {{ ( attendee.ticket.accessFrom || event.starts) | moment("MMMM DD / YYYY / hh a") }}
        </v-card-subtitle>
      </v-row>
      <v-row v-if="event.isOnlineEvent">
        <v-card-subtitle class="pb-0" :style="{'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}">
          <v-btn
            outlined color="#11CDEF" @click="goToOnlineEvent"
          >
            {{ $t('accessEvent') }}
          </v-btn>
        </v-card-subtitle>
      </v-row>
    </v-card-text>
    <template v-if="!event.isOnlineEvent">
      <v-card-text class="px-5">
        <v-row align="start" class="mx-0">
          <v-col cols="6" class="pa-0">
            <v-img :src="attendee.qrCode"> </v-img>
          </v-col>

          <v-col class="purchase-info" :style="{'color': ($vuetify.theme.dark ? '#fff' : '#1e1e1e')}">
            <p>
              {{ attendee.userId }}
            </p>
            <p>{{ attendee.name }}</p>
            <p class="text">{{ attendee.document }}</p>
            <p class="text">{{ attendee.phone[0] }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-5 pt-0 pb-4">
        <v-btn
          outlined color="#11CDEF" @click="downloadPDF"
        >
          {{ $t('download') }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import eventsService from '@/services/eventsService'
export default {
  name: 'PurchaseDetailAttendee',
  computed: {
    ...mapState({
      userInfo: state => state.authKeybe.userInfo
    })
  },
  methods: {
    goToOnlineEvent () {
      if (this.event.isOnlineEvent) {
        if (this.event.hasExternalEventUrl) {
          this.goToExternalOnlineEvent()
        } else {
          this.$router.push({
            name: 'VenueEventLiveStream',
            params: { id: this.event.slug }
          })
        }
      } else {
        this.$router.push({
          name: 'VenueEventDetail',
          params: { id: this.event.slug }
        })
      }
    },
    goToExternalOnlineEvent () {
      // :href="`${event.externalEventUrl}?code=${attendee.purchaseId}`"
      var eventWindow = window.open(`${this.event.externalEventUrl}?code=${this.attendee.purchaseId}`, '_blank')
      // console.log('eventWindow')
      // console.log(this.event.externalEventUrl)
      // console.log(eventWindow)
      window.addEventListener('message', (event) => {
        if (event.origin !== 'https://reconexiones.co') { return }
        if (event.data === 'keybeJsLoaded') {
          console.log('Sharing jwt with ' + this.event.externalEventUrl)
          eventWindow.postMessage({ jwt: this.userInfo.jwt }, this.event.externalEventUrl)
        }
      }, false)
    },
    downloadPDF () {
      eventsService.getAttendeePDF(this.attendee._id)
    }
  },
  props: {
    attendee: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.purchase-info {
  color: #172b4d;
  font-family: Montserrat;
}
.purchase-info p:nth-child(1) {
  font-size: 24px;
  font-weight: bold;
}

.purchase-info p:nth-child(2) {
  font-size: 15px;
  font-weight: 600;
}

.purchase-info .text {
  font-size: 13px;
  font-weight: normal;
}
</style>
