import Vue from 'vue'
export default {
  error (text) {
    // console.trace()
    Vue.prototype.$snotify.error(text, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      icon: 'https://storage.googleapis.com/keybeui/2.0/img/baseline_clear_white_36dp.png'
    })
  },
  success (text) {
    Vue.prototype.$snotify.success(text, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      icon: 'https://storage.googleapis.com/keybeui/2.0/img/baseline_check_white_36dp.png'
    })
  },
  info (text) {
    Vue.prototype.$snotify.info(text, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      icon: 'https://storage.googleapis.com/keybeui/2.0/img/baseline_info_white_36dp.png'
    })
  }
}
